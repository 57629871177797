
import request from "../request"

export function sendCodeMail(email) {
  return request({
    url: '/api/mail/email-code/code',
    method: 'post',
    data: email
  })
}

export function registerUser(registerUserForm) {
  return request({
    url: '/api/user/register',
    method: 'post',
    data: registerUserForm
  })
}
export function loginUser(loginForm) {
  return request({
    url: '/api/user/login',
    method: 'post',
    data: loginForm
  })
}
export function getUserInfo() {
  return request({
    url: '/api/user/get-login-info',
    method: 'get',
  })
}
export function twoStepLogin(loginForm) {
  return request({
    url: '/api/user/two-step-login',
    method: 'post',
    data: loginForm
  })
}
export function verifyEmail(email) {
  return request({
    url: '/api/mail/email-code/verify-email',
    method: 'post',
    data: email
  })
}



export function checkToken() {
  return request({
    url: '/api/user/checkToken',
    method: 'get',
  })
}

export function changePassword(changePasswordform) {
  return request({
    url: '/api/user/update-avatar-by-id',
    method: 'put',
    data: changePasswordform
  })
}
