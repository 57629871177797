<template>
  <div class="siderbaritem">
      <div class="icon"><slot name="icon"></slot></div>
      <div class="text"><slot name="title"></slot></div>
  </div>
</template>

<script>
export default {
  name:'SiderBarItem',
}
</script>

<style scoped>
.siderbaritem{
  height: 60px;
  border-bottom: 1px solid rgb(0, 0, 0);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: .6s;
  background: #48494E;
  
}
.text{
  font-size: 20px;
  font-weight: 600;
  width: calc(100% - 100px);
}
.icon{
  position: relative;
  width: 100px;
  height: 80px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}
.active{
  background: #28292c;
  color: #2EC5CE;
}
</style>
