<template>
  <div id="siderbar">
    <div class="userInfoBox">
      <div class="userImgBox">
        <img class="userImg" :src="userInfo.avatar" alt="">
      </div>

      <div class="username">{{userInfo.nickName}}</div>
    </div>
    <div class="siderMenu">
      <sider-bar-item v-for="(item,index) in siderBarList" :key="index" @click="isActive(index,item.path)" :class="{ active: activeIndex == index }">
        <template v-slot:title>
          {{item.title}}
        </template>
        <template v-slot:icon>
          <svg-icon :name='item.icon' size='24' color="#2EC5CE"/>
        </template>
      </sider-bar-item>
    </div>

    <div class="footBox">
      <div class="btnQuit" @click="logout()">
        <svg-icon name='logOut' size='24' color="#2EC5CE"/>
        <div class="logoutText">{{$t(`lang.logout`)}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '../SvgIcon/SvgIcon.vue'
import SiderBarItem from './SiderBarItem.vue'
import cookie from "js-cookie"

import {checkToken} from "@/axios/api/login"
export default {
  name:'SiderBar',
  components: {
    SiderBarItem,
    SvgIcon
  },
  data() {
    return {
      siderBarList:[
        {        
          title:'Home',
          icon: 'home',
          path: 'home'
        },
        {        
          title:'Account',
          icon: 'diana',
          path:'setting',
        },
        {        
          title:'Setting',
          icon: 'setting',
          path: 'setting'
        },

      ],
      activeIndex:'0',
      userInfo:{}
    }
  },
  mounted(){
    this.getUserInfo()
  },
  methods:{
    isActive(id,path){
      this.activeIndex = id
      this.$router.push("/usercenter/"+ path)
    },
    logout(){
      //清除cookie
      cookie.set('userInfo','')
      window.location.href = '/'
    },
    getUserInfo(){
      let userInfor = cookie.get('userInfo')
      if(!userInfor) {
        this.userInfo= null
        window.location.href = '/login'
      }
      userInfor = JSON.parse(userInfor)
      checkToken().then(res => {
        if(res.code==20000) {
          this.userInfo = userInfor
        } else {
          cookie.set('userInfo','')
          window.location.href = '/login'
        }
      })
    },
  },
}
</script>

<style scoped>
#siderbar{
  position: relative;
  width: 236px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  height: calc(100vh - 50px);
  background: #48494E;
}
.isActive{
  background: rgb(155, 142, 192);
}
.userInfoBox{
  position: relative;
  height: 220px;
  width: 100%;
  background: #2C2D30;
  border-bottom: 1px solid #000;
  padding-top: 40px;
  text-align: center;
}
.userImgBox{
  height: 85px;
  border: 2px solid #CD9B61;
  width: 85px;
  border-radius: 50%;
  margin: auto;
}
.userImg{
  display: flex;
  height: 85px;
  margin: 0px auto;
  box-sizing: border-box;
  border-radius: 42.5px;
  overflow: hidden;
  width: 85px;
}
.username{
  width: 100%;
  margin: 20px auto;
  text-align: center;
  color: aliceblue;
  font-weight: 900;
  font-family: "Hammer";
}
.footBox{
  position: absolute;
  width: 100%;
  height: 80px;
  background: #2C2D30;
  bottom: 0px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgb(19, 19, 19);
}
.siderMenu{
  width: 100%;
  height: calc(100% - 340px);
}
.btnQuit{
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 54px;
}
.logoutText{
  margin-left: 20px;
  color: rgb(192, 144, 144);
}
</style>
