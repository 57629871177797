<template>
<div class="body">
  <div id="usercenter">
    <sider-bar></sider-bar>
    <div class="routerView">
      <router-view></router-view>
    </div>
  </div>
</div>

</template>

<script>
import SiderBar from '@/components/siderBar/SiderBar.vue'
export default {
  name:'UserCenter',
  components: {
    SiderBar
  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
.body{
  padding-top: 50px;
  background: #000;
}
#usercenter{
  width: 100%;
  height: 100%;
  background: #212024;
  display: flex;
}
.routerView{
  flex: 1;
  height: 100%;
}
</style>
